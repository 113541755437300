import React from 'react';

import { portfolioItems } from './Portfolio';

const styles: any = {
    container: {
      padding: 20
    },
    cover: {
      cursor: 'pointer',
      padding: 5,
      position: 'relative'
    },
    coverHover: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0,0,0,0.1)',
      zIndex: 2,
      cursor: 'pointer'
    }
  }

interface PortfolioTileProps {
    index: number;
    selectedCover: number | undefined;
    height: number;
    onHover(index: number | undefined): void;
    onClick(key: string): void;
}

const PortfolioTile: React.FC<PortfolioTileProps> = (props: PortfolioTileProps) => {
    console.log('portfolioItems[props.index]', portfolioItems[props.index]);
    return (
        <div style={{maxHeight: props.height, marginBottom: 10}}>
            <div 
                style={styles.cover} 
                onMouseEnter={() => props.onHover(props.index)} 
                onMouseLeave={() => props.onHover(undefined)} 
                onClick={() => props.onClick(portfolioItems[props.index].key)}
            >
                {props.selectedCover === props.index ? <div style={styles.coverHover} /> : null}
                <img 
                    src={portfolioItems[props.index].coverImage} 
                    alt={portfolioItems[props.index].title} 
                    style={{width: '100%', maxHeight: props.height}}
                />
            </div>
        </div>
    )
}
export default PortfolioTile;