import React from 'react';

const logo = process.env.PUBLIC_URL + '/images/Header/Nat_logo.png';

interface TextProps {
  text: string;
  width?: number;
}

const Text: React.FC<TextProps> = (props: TextProps) => {
  const width = props.width !== undefined ? props.width: 425;
  return (
    <div style={{
      fontSize: 16,
      textAlign: 'left',
      paddingBottom: 30,
      maxWidth: width
    }}>
      {props.text}
    </div>
  )
}
export default Text;