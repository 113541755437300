import React from 'react';

/*

  link: #808080
  hover: #dddddd
  clicke: #fff
*/

const styles: any = {
  container: {
    background: '#2a2a2a',
    padding: 10
  },
  navContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  nav: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    display: 'flex'
  },
  navItem: {
    display: 'block',
    fontSize: 14,
    textTransform: 'uppercase'
  },
  navItemSelected: {
    display: 'block',
    fontSize: 14,
    textTransform: 'uppercase',
    color: '#AF47B2'
  }
}

interface FooterProps {
  onClick(route: string): void;
}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {

  console.log(window.location.pathname);

  return (
    <div style={styles.container}>
      <div style={styles.navContainer}>
        <ul style={styles.nav}>
          <li style={(window.location.pathname === '/portfolio' ? styles.navItemSelected : styles.navItem)} className="nav-button" onClick={() => props.onClick('/portfolio')}>Portfolio</li>
          <li style={(window.location.pathname === '/about' ? styles.navItemSelected : styles.navItem)} className="nav-button" onClick={() => props.onClick('/about')}>About</li>
          <li style={(window.location.pathname === '/payment' ? styles.navItemSelected : styles.navItem)} className="nav-button" onClick={() => props.onClick('/payment')}>Payment</li>
          <li style={(window.location.pathname === '/contact' ? styles.navItemSelected : styles.navItem)} className="nav-button" onClick={() => props.onClick('/contact')}>Contact</li>
        </ul>
      </div>
    </div>
  )
}
export default Footer;