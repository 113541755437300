import React from 'react';
import { Grid, Hidden } from '@material-ui/core';

import PublicContainer from '../../containers/Public';
import Title from '../../components/site/Title';
import Text from '../../components/site/Text';
import ActionButton from '../../components/inputs/ActionButton';

const logo = process.env.PUBLIC_URL + '/images/profile.webp';

const styles: any = {
  container: {

  },
  text: {
    fontSize: 15,
    textAlign: 'left',
    paddingBottom: 20
  },
  subText: {
    fontSize: 12,
    lineHeight: '1.5rem'
  },
  image: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '60%'
  }
}

const AboutPage: React.FC<{}> = (props) => {

  const handleClick = () => {
    window.open('https://external-public.s3-us-west-2.amazonaws.com/natalie-gray-resume.pdf', '_blank', 'fullscreen=yes');
  }

  return (
    <PublicContainer title="about">
      <Grid container>
        <Grid item xs={12} sm={12} md={6}>
          <div>
            <img src={logo} alt="Natalie Gray" style={styles.image} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div style={{ paddingTop: 40 }}>
            <Title title="NATALIE GRAY" />
            <Text text="Expert designer seeking a new digital design position to bring exceptional artistic abilities and passion, with attention to detail, years of design experience within a range of industries, and a willingness to learn and expand skillset wherever needed to develop the best solutions for the company’s success."></Text>
            <div>EDUCATION</div>
            <div style={styles.text}>
              <div style={styles.subText}>BACHELORS OF FINE ARTS DEGREE</div>
              <div style={styles.subText}>California State University Fullerton August 2005- May 2007</div>
              <div style={styles.subText}>San Jose State University August 2003- May 2005</div>
            </div>
            <div style={{marginTop: 80}}>
              <Hidden smDown>
                <ActionButton name="RESUME" onClick={handleClick} />
              </Hidden>
              <Hidden mdUp>
                <ActionButton name="RESUME" onClick={handleClick} fullWidth />
              </Hidden>
            </div>
          </div>
        </Grid>
      </Grid>
    </PublicContainer>
  );
}


export default AboutPage;
