import React from 'react';
import { withRouter } from 'react-router-dom';
import { find } from 'lodash';
import Lightbox from 'react-image-lightbox';

import { Grid, Hidden } from '@material-ui/core';

import PublicContainer from '../../containers/Public';

import { portfolioItems } from '../../components/portfolio/Portfolio';
import ActionButton from '../../components/inputs/ActionButton';
import Text from '../../components/site/Text';


const styles: any = {
  container: {

  },
  contentContainer: {

  },
  imageConatiner: {
    maxwidth: 350
  },
  img: {
    marginBottom: 20,
    width: '100%',
    cursor: 'pointer'
  },
  imgPhone: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
    maxWidth: 400,
    cursor: 'pointer'
  },
  title: {
    letterSpacing: '4px',
    fontSize: 20,
    marginBottom: 40,
    marginTop: 10,
    textTransform: 'uppercase',
    maxWidth: 270
  },
  clientTitle: {
    fontSize: 11
  },
  client: {
    fontSize: 18,
    fontStyle: 'italic'
  },
  clientItem: {
    fontWeight: 'bold'
  },
  desc: {
    marginTop: 10,
    fontSize: 15,
  },
  tag: {
    fontSize: 15
  }
}

interface PortfolioPageProps {
  history: any;
}

interface PortfolioPageState {
  portfolioItem: any;
  photoIndex: number,
  isOpen: boolean,
}

class PortfolioItemPage extends React.Component<PortfolioPageProps, PortfolioPageState> {
  constructor(props: PortfolioPageProps) {
    super(props);
    this.state = {
      portfolioItem: {
        title: '',
        clients: [],
        desc: '',
        key: '',
        coverImage: null,
        images: [],
        type: '',
        width: 0
      },
      photoIndex: 0,
      isOpen: false
    }
  }

  componentDidMount = () => {
    // load
    console.log(this.props.history)

    const key = (this.props.history.location.state ? this.props.history.location.state.key : '');
    const item = find(portfolioItems, { key });
    this.setState({ portfolioItem: item });

    console.log('item', item);
  }

  handleClick = (route: string) => {
    this.props.history.push(route);
  }

  handleBackClick = () => {
    this.props.history.push('/portfolio');
  }

  handleImageClick = (index: number) => {
    this.setState({
      isOpen: true,
      photoIndex: index
    })
  }

  render() {

    const { portfolioItem, photoIndex, isOpen } = this.state;
    const images = portfolioItem.images;
    return (
      <PublicContainer title="graymatterdev">
        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <div style={styles.imageContainer}>
              {images.map((image: any, index: number) =>
                <img src={image} alt="img" style={(portfolioItem.key === 'CareinnovationsBundles' ? styles.imgPhone : styles.img)} onClick={() => this.handleImageClick(index)} />
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={styles.contentContainer}>
              <div style={styles.title}>{this.state.portfolioItem.title}</div>
              <Text text={this.state.portfolioItem.desc} width={300} />
              <div style={styles.tagsContainer}>
                {this.state.portfolioItem.tags && this.state.portfolioItem.tags.map((tag: string) =>
                  <div style={styles.tag}>{tag}</div>
                )}
              </div>
              <div style={{ marginTop: 160 }}>
                <Hidden smDown>
                  <ActionButton name="BACK" onClick={this.handleBackClick} />
                </Hidden>
                <Hidden mdUp>
                  <ActionButton name="BACK" onClick={this.handleBackClick} fullWidth />
                </Hidden>
              </div>
            </div>
          </Grid>
        </Grid>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </PublicContainer>
    );
  }
}

export default withRouter<any, any>(PortfolioItemPage);
