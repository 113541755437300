import AboutPage from '../pages/about/About';
import ContactPage from '../pages/contact/Contact';
import PortfolioPage from '../pages/portfolio/Portfolio';
import PortfolioItemPage from '../pages/portfolio/PortfolioItem';
import PaymentPage from '../pages/payment/Payment';

const routes = {
  public: [
    {
      name: 'Portfolio',
      path: '/',
      exact: true,
      component: PortfolioPage
    },
    {
      name: 'Portfolio',
      path: '/portfolio',
      exact: true,
      component: PortfolioPage
    },
    {
      name: 'Portfolio',
      path: '/portfolio/:key',
      exact: true,
      component: PortfolioItemPage
    },
    {
      name: 'About',
      path: '/about',
      exact: true,
      component: AboutPage
    },
    {
      name: 'Contact',
      path: '/contact',
      exact: true,
      component: ContactPage
    },
    {
      name: 'Portfolio',
      path: '/porfolio',
      exact: true,
      component: PortfolioPage
    },
    {
      name: 'Payment',
      path: '/payment',
      exact: true,
      component: PaymentPage
    },
    {
      name: 'Payment',
      path: '/payment/:id',
      exact: true,
      component: PaymentPage
    },
  ],
  private: [

  ]
}

export default routes;