import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Grid, createStyles, Theme } from '@material-ui/core';
import Gallery from 'react-grid-gallery';

import PortfolioTile from './PortfolioTile';

const careinnovationsImage = process.env.PUBLIC_URL + '/images/CareinnovationBrochure/CPT-codes-Brochurecover.png';
const careinnovationsImage2 = process.env.PUBLIC_URL + '/images/CareinnovationBrochure/CPT-codes-BrochureDRAFT3.png';

const careinnovationsCorpImage1 = process.env.PUBLIC_URL + '/images/Careinnovations/careinnovations1.png';
const careinnovationsCorpImage2 = process.env.PUBLIC_URL + '/images/Careinnovations/careinnovations2.png';
const careinnovationsWalmart = process.env.PUBLIC_URL + '/images/Careinnovations/Walmart.png';
const careinnovationsWalmart2 = process.env.PUBLIC_URL + '/images/Careinnovations/Walmart2.png';
const careinnovationsWalmart3 = process.env.PUBLIC_URL + '/images/Careinnovations/Walmart3.png';
const careinnovationsWalmart2Large = process.env.PUBLIC_URL + '/images/Careinnovations/Walmart2-large.png';
const careinnovationsWalmart3Large = process.env.PUBLIC_URL + '/images/Careinnovations/Walmart3-large.png';
const careinnovationsBundles = process.env.PUBLIC_URL + '/images/Careinnovations/Bundles.webp';
const careinnovationsBundles2 = process.env.PUBLIC_URL + '/images/Careinnovations/Bundles2.webp';
const careinnovationsBundles3 = process.env.PUBLIC_URL + '/images/Careinnovations/Bundles3.webp';

const christmasImage = process.env.PUBLIC_URL + '/images/ChristmasCards/Christmascard1.png';
const christmasImage2 = process.env.PUBLIC_URL + '/images/ChristmasCards/Christmascard2.png';
const christmasImage3 = process.env.PUBLIC_URL + '/images/ChristmasCards/Christmascard3.png';

const craneSpecImage = process.env.PUBLIC_URL + '/images/CraneSpec/cranespecinc.png';
const craneSpecImage2 = process.env.PUBLIC_URL + '/images/CraneSpec/cranespecinc1.png';
const craneSpecImage3 = process.env.PUBLIC_URL + '/images/CraneSpec/cranespecinc2.png';


const kibookyImage = process.env.PUBLIC_URL + '/images/Kibooky/Kibooky_leaflet1.png';
const kibookyImage2 = process.env.PUBLIC_URL + '/images/Kibooky/Kibooky_leaflet2.png';
const kibookyImage3 = process.env.PUBLIC_URL + '/images/Kibooky/Kibooky_leaflet3.png';

const logosImage = process.env.PUBLIC_URL + '/images/Logos/logos.png';
const logosImage1 = process.env.PUBLIC_URL + '/images/Logos/LogoPhones.png';
const logosImage2 = process.env.PUBLIC_URL + '/images/Logos/Grizzly-biz-card.png';
const logosImage3 = process.env.PUBLIC_URL + '/images/Logos/inthemoment.png';
const logosImage4 = process.env.PUBLIC_URL + '/images/Logos/woodcreek.png';
const logosImage5 = process.env.PUBLIC_URL + '/images/Logos/LogoPour.png';
const logosImage6 = process.env.PUBLIC_URL + '/images/Logos/LogoPour2.png';
const logosImage7 = process.env.PUBLIC_URL + '/images/Logos/LogoSquares.png';


const portablePourImage = process.env.PUBLIC_URL + '/images/PortablePour/PORTABLE-POUR.png';
const portablePourImage2 = process.env.PUBLIC_URL + '/images/PortablePour/portable-pour-shirt.png';
const portablePourImage3 = process.env.PUBLIC_URL + '/images/PortablePour/portable-pour-logo.png';

const babysFirstYearImage = process.env.PUBLIC_URL + '/images/BabysFirstYear/BFYJ_cover.png';
const babysFirstYearImage2 = process.env.PUBLIC_URL + '/images/BabysFirstYear/BFYJ_INSIDEPAGE.png';
const babysFirstYearImage3 = process.env.PUBLIC_URL + '/images/BabysFirstYear/BFYJ_INSIDEPAGE1.png';

const fauxPaw = process.env.PUBLIC_URL + '/images/FauxPaw/FauxPaw.png';
const fauxPaw2 = process.env.PUBLIC_URL + '/images/FauxPaw/FauxPaw2.png';
const fauxPaw3 = process.env.PUBLIC_URL + '/images/FauxPaw/FauxPaw3.png';

const beFitDavisImage = process.env.PUBLIC_URL + '/images/BeFitDavis/BeFitDavis_APP.png';
const beFitDavisImage2 = process.env.PUBLIC_URL + '/images/BeFitDavis/BeFitDavis_app2.png';


const nowPerformance1 = process.env.PUBLIC_URL + '/images/NowPerformance/NP-slide-1.jpg';
const nowPerformance2 = process.env.PUBLIC_URL + '/images/NowPerformance/NP-slide-2.jpg';
const nowPerformance3 = process.env.PUBLIC_URL + '/images/NowPerformance/NP-slide-3.jpg';
const nowPerformance4 = process.env.PUBLIC_URL + '/images/NowPerformance/NP-slide-4.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
      [theme.breakpoints.down("sm")]: {
        padding: 0
      },
    },
    portContainer: {
      maxWidth: 970, 
      margin: '0px auto' 
    }
  })
);


export interface PortfolioItem {
  title: string;
  clients: string[];
  desc: string;
  tags: string[];
  key: string;
  coverImage: any;
  images: string[],
  type: string;
  height: number;
}

export const portfolioItems: PortfolioItem[] = [
  {
    title: "BABY'S FIRST YEAR JOURNAL",
    clients: ['Faux Paw Productions and', 'Author Christine Harris'],
    desc: `Worked with New York's Best Selling author Christine Harris to create a new updated edition of the Baby's First Year Journal.`,
    tags: ['Typography', 'Illustration', 'Book Design Layout', 'Editing'],
    key: 'BabysFirstJournal',
    coverImage: babysFirstYearImage3,
    images: [babysFirstYearImage, babysFirstYearImage2, babysFirstYearImage3],
    type: 'wide',
    height: 275
  },
  {
    title: 'CARE INNOVATIONS',
    clients: ['Careinnovations'],
    desc: 'Worked with a small team of healthcare professionals to illustrate through info-graphs, the reimbursements for RPM and Telehealth.',
    tags: ['Illustration', 'Typography', 'Brochure Design'],
    key: 'Careinnovations',
    coverImage: careinnovationsImage,
    images: [careinnovationsImage, careinnovationsImage2],
    type: 'tall',
    height: 275
  },
  // {
  //   title: 'CRANE SPEC INC',
  //   clients: ['Crane Spec Inc.'],
  //   desc: 'Designed a logo and a website for a client who needed branding for his company Crane Spec Inc. Created an intuitive user experience ',
  //   tags: [],
  //   key: 'CranespecInc',
  //   coverImage: craneSpecImage,
  //   images: [craneSpecImage, craneSpecImage2, craneSpecImage3],
  //   type: 'wide',
  //   height: 275
  // },
  {
    title: 'Now Performance',
    clients: ['NowPerformance'],
    desc: 'Created powerpoint presentation slides for a pitch deck. Designed interface for fitness tracker app.',
    tags: [],
    key: 'CranespecInc',
    coverImage: nowPerformance1,
    images: [nowPerformance1, nowPerformance2, nowPerformance3, nowPerformance4],
    type: 'wide',
    height: 300
  },
  {
    title: 'LOGO DESIGN',
    clients: ['Bunpinos', 'Tech Events', 'Boxwood & Finch', 'BeFitDavis', 'Gizzly Mechanical Inc', 'Cloud Cruiser', 'Gallery St. Croix', 'In the Moment Media', 'Woodcreek Villa LLC', 'Refurb World', 'Tootr'],
    desc: 'A portfolio of logos designed for clients from tech and fitness to boutique and the entertainment industry. Knowledge in color theory and typography and proficient in Adobe Creative Suite.',
    tags: [],
    key: 'DesignLogos',
    coverImage: portablePourImage,
    images: [logosImage7, logosImage2, logosImage5, logosImage6],
    type: 'wide',
    height: 275
  },
  {
    title: 'CARE INNOVATIONS',
    clients: ['Careinnovations'],
    desc: 'Working with a small team of healthcare and business professionals, I designed the graphics and user interface for a concept called Bundles of Joy, to be presented to Walmart.',
    tags: [],
    key: 'CareinnovationsBundles',
    coverImage: logosImage1,
    images: [careinnovationsBundles, careinnovationsBundles2, careinnovationsBundles3],
    type: 'wide',
    height: 275
  },
  {
    title: 'KIBOOKY',
    clients: ['Kibooky'],
    desc: "Conceptualized, illustrated and designed a customized children's book mobile application. Presented and described in brochure layout.",
    tags: [],
    key: 'Kibooky',
    coverImage: kibookyImage,
    images: [kibookyImage, kibookyImage2, kibookyImage3],
    type: 'wide',
    height: 275
  },
  {
    title: 'CARE INNOVATIONS',
    clients: ['Careinnovations'],
    desc: 'Working with a small team of healthcare and business professionals at Care Innovations, I designed white label brochures for clients including Walmart and TrialJectory.',
    tags: [],
    key: 'CareinnovationsWalmart',
    coverImage: careinnovationsWalmart,
    images: [careinnovationsWalmart, careinnovationsWalmart2Large, careinnovationsWalmart3Large],
    type: 'wide',
    height: 275
  },
  {
    title: "FAUX PAW PRODUCTIONS INC",
    clients: ['Faux Paw Productions and', 'Author Christine Harris'],
    desc: 'As creative director for over 14 years, I built, managed and designed projects ranging from book design, website design, product design, logos, photography manipulation and much more.',
    tags: [''],
    key: 'FauxPaw',
    coverImage: fauxPaw,
    images: [fauxPaw, fauxPaw2, fauxPaw3],
    type: 'wide',
    height: 275
  },
  {
    title: "BE FIT DAVIS",
    clients: ['BeFitDavis'],
    desc: "From logo concept to the user interface, I created a community based application for client's fitness business model.",
    tags: [''],
    key: 'BeFitDavis',
    coverImage: beFitDavisImage,
    images: [beFitDavisImage, beFitDavisImage2],
    type: 'wide',
    height: 275
  }

];


interface PortfolioProps {
  onClick(key: string): void;
}

const Portfolio: React.FC<PortfolioProps> = (props: PortfolioProps) => {

  const classes = useStyles();
  const [selectedCover, setCover] = React.useState<number>();

  return (
    <div className={classes.container}>
      <div className={classes.portContainer}>
        <Grid container >
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <PortfolioTile
              index={0}
              selectedCover={selectedCover}
              height={210}
              onHover={(index: number) => setCover(index)}
              onClick={props.onClick}
            />
            <PortfolioTile
              index={3}
              height={210}
              selectedCover={selectedCover}
              onHover={(index: number) => setCover(index)}
              onClick={props.onClick}
            />
            <PortfolioTile
              index={6}
              selectedCover={selectedCover}
              height={210}
              onHover={(index: number) => setCover(index)}
              onClick={props.onClick}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <PortfolioTile
              index={1}
              selectedCover={selectedCover}
              height={255}
              onHover={(index: number) => setCover(index)}
              onClick={props.onClick}
            />
            <PortfolioTile
              index={4}
              selectedCover={selectedCover}
              height={165}
              onHover={(index: number) => setCover(index)}
              onClick={props.onClick}
            />
            <PortfolioTile
              index={7}
              selectedCover={selectedCover}
              height={205}
              onHover={(index: number) => setCover(index)}
              onClick={props.onClick}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <PortfolioTile
              index={2}
              selectedCover={selectedCover}
              height={205}
              onHover={(index: number) => setCover(index)}
              onClick={props.onClick}
            />
            <PortfolioTile
              index={5}
              selectedCover={selectedCover}
              height={205}
              onHover={(index: number) => setCover(index)}
              onClick={props.onClick}
            />
            <PortfolioTile
              index={8}
              selectedCover={selectedCover}
              height={205}
              onHover={(index: number) => setCover(index)}
              onClick={props.onClick}
            />
          </Grid>
        </Grid>
      </div>
    </div>

  )
}
export default Portfolio;