import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { Hidden, createStyles, Theme } from '@material-ui/core';

const logo = process.env.PUBLIC_URL + '/images/Header/Nat_logo.png';

/*

  link: #808080
  hover: #dddddd
  clicke: #fff
*/

const styles: any = {
  container: {
    padding: 20,
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: 20
  },
  logo: {
    width: 500
  },
  logoMobile: {
    width: 200
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    letterSpacing: '0.3rem'
  },
  titleMobile: {
    textAlign: 'center',
    fontSize: 12,
    letterSpacing: '0.3rem'
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 20,
      textAlign: 'center',
      cursor: 'pointer',
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 5
      }
    },
    logo: {
      width: 500,
      [theme.breakpoints.down("sm")]: {
        width: 200
      }
    },
    logoMobile: {
      width: 200
    },
    title: {
      textAlign: 'center',
      fontSize: 20,
      letterSpacing: '0.3rem',
      [theme.breakpoints.down("sm")]: {
        fontSize: 12
      }
    }
  })
);

interface LogoProps {
  onClick(route: string): void;
}

const Logo: React.FC<LogoProps> = (props: LogoProps) => {
  
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={() => props.onClick('/')}>
        <img src={logo} className={classes.logo} alt="Natalie Gray" />
        <div className={classes.title}>DESIGN PORTFOLIO</div>
    </div>
  )
}
export default Logo;