import React from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import PublicContainer from '../../containers/Public';

import InvoiceForm from './components/InvoiceForm';
import PaymentForm from './components/PaymentForm';
import InvoiceDetail from './components/InvoiceDetail';
import { Invoice, Customer } from './entities/payment';


const styles: any = {
  container: {
    maxWidth: 600,
    margin: '0px auto'
  },
  receiptContainer: {
    padding: 25,
    backgroundColor: '#ddd',
    borderRadius: 4
  },
  receiptText: {
    fontSize: 16
  },
  receiptTextBottom: {
    fontSize: 16,
    marginTop: 5
  }
}

interface PaymentPageProps {
  history: any;
}

interface PaymentPageState {
  action: number;
  invoice: Invoice;
  invoiceNumber: string;
  customer: Customer;
  email: string;
  error: string;
  charge: any;
  saving: boolean;
}

class PaymentPage extends React.Component<PaymentPageProps, PaymentPageState> {
  constructor(props: PaymentPageProps) {
    super(props);
    this.state = {
      action: 0,
      error: '',
      invoice: {} as Invoice,
      invoiceNumber: '',
      customer: {} as Customer,
      email: '',
      charge: '',
      saving: true
    }
  }

  componentDidMount = () => {
    console.log(this.props.history)
    const path = this.props.history.location.pathname;
    const pathParts = path.split('/');
    if (pathParts.length === 3) {
      
      this.setState({invoiceNumber: pathParts[2]})
    }
  }

  getApiUrl = () => {
    // if (window.location.host.indexOf('localhost') > -1) {
    //   return 'http://localhost:3003';
    // } else {
      return 'https://e22r88mhai.execute-api.us-west-2.amazonaws.com/prod';
    //}
  }

  handleInvoiceClick = (invoiceName: string) => {
    axios.get(`${this.getApiUrl()}/invoice/number/${invoiceName}`).then((res) => {
      if (res.data) {

        const invoice: Invoice = res.data;

        axios.get(`${this.getApiUrl()}/customer/${invoice.customerId}`).then((res) => {
          
          if (res.data) {

            this.setState({
              invoice: invoice,
              customer: res.data,
              action: 1,
              saving: false
            })

          } else {
            this.setState({error: 'No customer found', saving: false});
          }

        }).catch((error) => {
          this.setState({error: 'Error getting invoice', saving: false})
        });

        
      } else {
        this.setState({error: `${invoiceName} not found`, saving: false});
      }
    }).catch((error) => {
      this.setState({error: 'Error getting invoice', saving: false})
    });
  }

  handlePaymentClick = (cardNumber: string, expirationDate: string, cvcCode: string, email: string) => {

    this.setState({saving: true});

    const expParts = expirationDate.split('/');
    cardNumber = cardNumber.replace(/-/g, '');

    const data = {
      number: cardNumber,
      expMonth: expParts[0],
      expYear: expParts[1],
      cvc: cvcCode,
      email,
      invoiceId: this.state.invoice.id
    }
    axios.post(`${this.getApiUrl()}/payment/charge`, data).then((res) => {

      if (res.data.errorMessage) {
        this.setState({error: res.data.errorMessage, saving: false})
      } else {
        this.setState({action: 2, email, charge: res.data, saving: false});
      }

    }).catch((error) => {
      this.setState({error: 'Error charging card. Please review all inputs.', saving: false})
    });
    
  }

  render() {

    return (
      <PublicContainer title="contact" msg={this.state.error}>
        <div style={styles.container}>
          {this.state.action === 0 && 
            <InvoiceForm onClick={this.handleInvoiceClick} invoiceNumber={this.state.invoiceNumber}  />
          }
          {this.state.action === 1 && 
            <div>
              <InvoiceDetail invoice={this.state.invoice} customer={this.state.customer} />
              <div style={{marginTop: 20}}>
                <PaymentForm onClick={this.handlePaymentClick} saving={this.state.saving} />
              </div>
            </div>
          }
          {this.state.action === 2 && 
            <div style={styles.receiptContainer}>
              <div style={styles.receiptText}>
                Transaction Receipt: <span style={{fontWeight: 'bold'}}>{this.state.charge.id}</span>
              </div>
              <div style={styles.receiptTextBottom}>
                Payment successful! Confirmation email has been sent too: <span style={{fontWeight: 'bold'}}>{this.state.email}</span>
              </div>
            </div>
          }
        </div>
      </PublicContainer>
    );
  }
}

export default withRouter<any, any>(PaymentPage);
