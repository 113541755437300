import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

import { Snackbar, Container, IconButton, Hidden } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Logo from '../components/site/Logo';
import Footer from '../components/site/Footer';

const styles: any = {
  container: {
    marginBottom: 80,
    minHeight: 400
  },
  title: {
    color: '#8AADA2',
    fontSize: 64,
    marginTop: '3%',
    marginBottom: '3%'
  },
  titleSmall: {
    color: '#8AADA2',
    fontSize: 42,
    marginTop: '3%',
    marginBottom: '3%'
  }
};

interface PublicLayoutProps {
  history: any;
  title?: string;
  msg?: string;
}

interface PublicLayoutState {
  open: boolean;
}

class PublicLayout extends Component<PublicLayoutProps, PublicLayoutState> {

  constructor(props: PublicLayoutProps) {
    super(props);
    this.state = {
      open: false
    }
  }

  componentDidUpdate = (prevProps:any, prevState:any) => {
    if (prevProps.msg !== this.props.msg) {
      this.setState({open: true});
    }
  }
  
  handleClick = (router: string) => {
    this.props.history.push(router);
  }

  handleClose = () => {
    this.setState({open: false});
  }

  render() {
    const title = this.props.title || 'graymatterdev';
    const cx = classNames({
      page: true,
      'page--prev': this.props.history.location.state && this.props.history.location.state.prev,
    });
    return (
      <Container>
        <div className="content">
          <Logo onClick={this.handleClick} />
          <div style={styles.container}>
            {this.props.children}
          </div>
          <Footer onClick={this.handleClick} />
        </div>
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.open}
          autoHideDuration={6000}
          onClose={this.handleClose}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{this.props.msg}</span>}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Container>
    );
  }
}
export default withRouter<any, any>(PublicLayout);
