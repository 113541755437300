import React from 'react';

const logo = process.env.PUBLIC_URL + '/images/Header/Nat_logo.png';

/*
  link: #808080
  hover: #dddddd
  clicke: #fff
*/

const styles: any = {
  container: {
    paddingTop: 20,
    paddingBottom: 40,
    maxWidth: 250
  },
  title: {
    fontSize: 20,
    textTransform: 'uppercase',
    textAlign: 'left',
    letterSpacing: '0.2rem',
    
  }
}

interface TitleProps {
  title: string;
}

const Title: React.FC<TitleProps> = (props: TitleProps) => {
  return (
    <div style={styles.container}>
      <div style={styles.title}>{props.title}</div>
    </div>
  )
}
export default Title;