import React from 'react';
import { withRouter } from 'react-router-dom';
import PublicContainer from '../../containers/Public';
import TextInput from '../../components/inputs/TextInput';
import ActionButton from '../../components/inputs/ActionButton';

const styles: any = {
  container: {

  }
}

interface ContactPageProps {
  history: any;
}

interface ContactPageState {
  name: string;
  email: string;
  description: string;
}

class ContactPage extends React.Component<ContactPageProps, ContactPageState> {
  constructor(props: ContactPageProps) {
    super(props);
    this.state = {
      name: '',
      email: '',
      description: ''
    }
  }

  componentDidMount = () => {
    // load
  }

  handleClick = (key: string) => {
    this.props.history.push(`/portfolio/${key}`, { key });
  }

  handleNameChange = (event: any) => {
    this.setState({name: event.target.value});
  }
  
  handleEmailChange = (event: any) => {
    this.setState({email: event.target.value});
  }

  handleDescriptionChange = (event: any) => {
    this.setState({description: event.target.value});
  }

  handleContactClick = () => {
    // request
  }

  render() {

    return (
      <PublicContainer title="graymatterdev"> 
        <div style={{maxWidth: 500, margin: '0px auto'}}>
          <TextInput 
            type="string"
            placeholder="Name"
            title="Name"
            result={this.state.name}
            onChange={this.handleNameChange} 
          />
          <TextInput 
            type="string"
            placeholder="Email"
            title="Email"
            result={this.state.email}
            onChange={this.handleEmailChange} 
          />
          <TextInput 
            type="string"
            placeholder="Description"
            title="Description"
            result={this.state.description}
            onChange={this.handleDescriptionChange} 
            multiline
            multilineMaxRows={10}
          />
          <div style={{marginTop: 20}}>
            <ActionButton name="CONTACT" onClick={this.handleContactClick} fullWidth />
           </div>
        </div>
      </PublicContainer>
    );
  }
}

export default withRouter<any, any>(ContactPage);
