import React from 'react';
import { withRouter } from 'react-router-dom';

import PublicContainer from '../../containers/Public';
import Portfolio from '../../components/portfolio/Portfolio';

const styles: any = {
  container: {

  }
}

interface PortfolioPageProps {
  history: any;
}

class PortfolioPage extends React.Component<PortfolioPageProps, {}> {
  constructor(props: PortfolioPageProps) {
    super(props);
  }

  componentDidMount = () => {
    // load
  }

  handleClick = (key: string) => {
    this.props.history.push(`/portfolio/${key}`, { key });
  }

  render() {

    return (
      <PublicContainer title="graymatterdev"> 
        <Portfolio onClick={this.handleClick} />
      </PublicContainer>
    );
  }
}

export default withRouter<any, any>(PortfolioPage);
