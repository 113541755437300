import React from 'react';
import DocumentTitle from 'react-document-title';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TransitionGroup, CSSTransition } from "react-transition-group";

import './App.css';
import 'react-image-lightbox/style.css';
import routes from './config/routes';

const styles: any = {
  section: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0
  },
  group: {
    position: 'relative'
  }
}

const App: React.FC = () => {
  return (
    <DocumentTitle title="Natalie Gray">
      <Router>
        <div className="App">
          <main>
            <Route
              render={({ location }) => {
                const { pathname } = location;
                return (
                  <TransitionGroup style={styles.group}>
                    <CSSTransition 
                      key={pathname}
                      classNames="fade"
                      timeout={{
                        enter: 1000,
                        exit: 1000,
                      }}
                    >
                      <Route
                        location={location}
                        render={() => (
                          <section style={styles.section}>
                            <Switch>
                              {routes.private.map((route: any) =>
                                <Route 
                                  key={route.path}
                                  path={route.path}
                                  exact={route.exact}
                                  component={route.component} 
                                  roles={route.roles} />
                              )}
                              {routes.public.map((route: any) =>
                                <Route 
                                  key={route.path}
                                  path={route.path}
                                  exact={route.exact}
                                  component={route.component} />
                              )}
                            </Switch>
                          </section>
                      )}
                      />
                    </CSSTransition>
                  </TransitionGroup>
                );
              }}
            />
          </main>
        </div>
        <ToastContainer />
      </Router>
    </DocumentTitle>
  );
}

export default App;


